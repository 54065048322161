import { SET_CHATROOM, SET_USERS } from '../actions';

export const setChatroom = (chatroomID) => ({
  type: SET_CHATROOM,
  payload: chatroomID,
});

export const setUsers = (users) => ({
  type: SET_USERS,
  payload: users,
});
