import { SET_CHATROOM, SET_USERS } from '../actions';

const INIT_STATE = {
  chatroom: null,
  users: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CHATROOM:
      return { ...state, chatroom: action.payload };
    case SET_USERS:
      return { ...state, users: action.payload };
    default:
      return { ...state };
  }
};
