import {
  getUser,
  setUser,
  deleteUser,
  deleteTokens,
  DeleteNotification,
} from 'utils/storageProvider';

const initialState = {
  user: getUser(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      const { ...user } = action.payload;
      setUser(user);
      return { ...state, user };
    case 'DELETE_USER':
      deleteUser();
      deleteTokens();
      DeleteNotification();
      return { ...state, user: null };
    default:
      return { ...state };
  }
};
