import cookie from 'js-cookie';

const USER_KEY = 'learning.user';
const ACCESS_TOKEN_KEY = 'learning.accessToken';
const REFRESH_TOKEN_KEY = 'learning.refreshToken';

export const getUser = () => {
  try {
    const data = cookie.get(USER_KEY);
    if (data) {
      return JSON.parse(data);
    }
  } catch (err) {
    // It can fail silently
    return null;
  }
  return null;
};

export const setUser = (user) => {
  cookie.set(USER_KEY, JSON.stringify(user));
};

export const deleteUser = () => {
  cookie.remove(USER_KEY);
};

export const DeleteNotification = () => {
  cookie.remove('MessagesNotf');
};

export const setTokens = (tokens) => {
  cookie.set(ACCESS_TOKEN_KEY, tokens.access_token);
  // cookie.set(REFRESH_TOKEN_KEY, tokens.refreshToken, {
  //   expires: new Date(tokens.refreshTokenExpiry),
  // });
};

export const getTokens = () => {
  const parsedTokens = {};
  try {
    const accessToken = cookie.get(ACCESS_TOKEN_KEY);
    // const refreshToken = cookie.get(REFRESH_TOKEN_KEY);
    if (accessToken) {
      parsedTokens.accessToken = accessToken;
    }
    // if (refreshToken) {
    //   parsedTokens.refreshToken = refreshToken;
    // }
  } catch (err) {
    // It can fail silently
  } finally {
    return parsedTokens;
  }
};

export const deleteTokens = () => {
  cookie.remove(ACCESS_TOKEN_KEY);
  cookie.remove(REFRESH_TOKEN_KEY);
};
